import React, { useContext, useState, createContext, useEffect } from "react";
import { BrowserRouter as Router, useHistory, Route, Redirect } from "react-router-dom";

// Mui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

// Pages
import Email from "./Email";
import Billing from "./Billing";
import Shipping from "./Shipping";
// import Payment from "./Payment";
import PaymentNew from "./Payment";
import Confirmation from "./Confirmation";
import Cart from "./Cart";
import OrderDetails from "./OrderDetails";
import Duo from "./Duo";

// Custom components
import { UserContext } from "../../../reducer/userContext";
import SkuModal from "../../shared/SkuModal";

import { createAbandonedCart } from "../../../actions/abandonedCartRoute";
import { getLandingPageInfo, GetNavbar, GetHelmet } from "../../../utils/config";
import { getServer } from "../../../configs/backend";
import ContinueShopping from "./continueShopping";
import { settings } from "../../../settings";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
    getProducts,
    getSubscriptions,
    getLoading,
    updateCheckoutData,
    getCheckoutDetails,
    getDuoUser,
    updateDuoUser,
    setProducts,
} from "../../../store/reducers/checkout";
import { snackbarEnqueuedAction, generateKey } from "../../../store/reducers/snackbars";
import { getRequest } from "../../../actions/requests";

// hooks
import useModal from "../../../hooks/useModal";

// Assets
import important from "../../../assets/shared/important.png";
import { getAllOrders, setIsPromocodeValidated, setPromocode, addSelectedSku } from "../../../store/reducers";

export const CheckoutContext = createContext();

const useStyles = makeStyles(theme => ({
    paper: {
        margin: theme.spacing(2, 1, 2, 2),
        padding: theme.spacing(3),
        [theme.breakpoints.down(600)]: {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

const Checkout = ({ baseurl = "", hideNav = false }) => {
    const [Modal, presentSkuModal, dismissSkuModal] = useModal();
    // notifications functions
    const pushNotification = (...args) => dispatch(snackbarEnqueuedAction(...args));

    const { brand, market, brandAcronym } = getLandingPageInfo();

    const AKTIVATION_DUO_ID = "b0ohOyDVJkmxsCaVVWQO";

    // redux
    const dispatch = useDispatch();
    const hist = useHistory();
    const products = useSelector(getProducts);
    const subscriptions = useSelector(getSubscriptions);
    const loading = useSelector(getLoading);
    const checkoutDetails = useSelector(getCheckoutDetails);
    const orders = useSelector(getAllOrders);

    // states
    const { user } = useContext(UserContext);

    // states
    const [kitProductWithSku, setKitProductWithSku] = useState(null);
    const [itemToModify, setItemToModify] = useState(null);
    const [isDuo, setIsDuo] = useState(false);
    // const [duoUser, setDuoUser] = useState({});
    const duoUser = useSelector(state => getDuoUser(state));
    const setDuoUser = data => dispatch(updateDuoUser(data));
    const [isTransactionCompleted, setIsTransactionCompleted] = useState(false);
    const [showCart, setShowCart] = useState(true);
    const [steps, setSteps] = useState({
        email: {
            url: `${baseurl}/checkout`,
            label: "Courriel",
            component: Email,
            show: true,
        },
        billing: {
            url: `${baseurl}/checkout/facturation`,
            label: "Facturation",
            component: Billing,
            show: true,
        },
        shipping: {
            url: `${baseurl}/checkout/expedition`,
            label: "Expédition",
            component: Shipping,
            show: false,
        },
        duo: {
            url: `${baseurl}/checkout/duo`,
            label: "Duo",
            component: Duo,
            show: false,
        },
        payment: {
            url: `${baseurl}/checkout/paiement`,
            label: "Paiement",
            component: PaymentNew,
            show: true,
        },
        confirmation: {
            url: `${baseurl}/checkout/confirmation`,
            label: "Confirmation",
            component: Confirmation,
            show: true,
        },
    });
    const [activeStep, setActiveStep] = useState(0);
    const [stripePaymentMethod, setStripePaymentMethod] = useState("");
    const [stripeCustomerId, setStripeCustomerId] = useState("");
    const [promoCodeValidated, setPromoCodeValidated] = useState(false);
    const [upsellAccepted, setUpsellAccepted] = useState(false);
    const classes = useStyles();

    /*
     * @param hist history a reference of the history object inside the given component
     *                     using a reference from the context caused a bug where the DOM would
     *                     not re-render
     * @param data any data to be sent to redux
     */
    const goToNextPage = (gotoFn, currentUrl, data) => {
        const nextStep = getNextActiveStep(currentUrl);
        if (data) dispatch(updateCheckoutData(data));
        gotoFn(nextStep.url);
    };

    const gotToPreviousStep = hist => {
        getPreviousActiveStep(hist.location.pathname);
        hist.goBack();
    };

    /** Change the show property of the given step of the "steps" state.
     *
     * @param stepName string step's name
     * @param show boolean whether to show the step or not
     * */
    const changeStepShow = (stepName, show) => {
        setSteps(prev => ({
            ...prev,
            [stepName]: { ...prev[stepName], show },
        }));
    };

    const getPreviousActiveStep = currentUrl => {
        const showableSteps = Object.values(steps).filter(s => s.show);
        const indexofThisStep = showableSteps.findIndex(s => s.url === currentUrl);

        setActiveStep(indexofThisStep - 1);
        return showableSteps[indexofThisStep - 1];
    };

    /*
     *  Gets the next step in the "steps" object. We first filter the "steps"
     *  object to only show steps that have showable. We then get the index of
     *  the current step and increment it by one. Finally, we return the first step of the next steps
     *
     * @param currentUrl string The current url of the current page. Used to determine the current index of the active step
     * */
    const getNextActiveStep = currentUrl => {
        const showableSteps = Object.values(steps).filter(s => s.show);
        const indexofThisStep = showableSteps.findIndex(s => s.url === currentUrl);
        const nextSteps = showableSteps.slice(indexofThisStep + 1);
        setActiveStep(indexofThisStep + 1);
        return nextSteps[0];
    };

    const sendAbandonnedCart = e => {
        if (window.location.pathname === "/checkout/confirmation") return;
        const productIds = products.reduce((acc, value) => {
            acc.push(value.id);
            return acc;
        }, []);

        const subscriptionIds = subscriptions.reduce((acc, value) => {
            acc.push(value.id);
            return acc;
        }, []);

        if (!isTransactionCompleted) {
            let data = {};

            if (user) {
                data = {
                    email: user.email.toLowerCase(),
                    firstName: user.firstName,
                    lastName: user.lastName,
                    productIds,
                    subscriptionIds,
                    source: brand,
                };
            } else {
                data = {
                    email: checkoutDetails.email.toLowerCase(),
                    firstName: checkoutDetails.firstName,
                    lastName: checkoutDetails.lastName,
                    productIds,
                    subscriptionIds,
                    source: brand,
                };
            }
            if (data.email) {
                var blobData = new window.Blob([JSON.stringify(data)], {
                    type: "application/x-www-form-urlencoded",
                });
                navigator.sendBeacon(`${getServer()}/api/v1/${createAbandonedCart}`, blobData);
            }
        }
    };

    const changeProductSku = (productId, sku) => {
        console.log(productId, sku);
        const modifiedProducts = products.map(product => {
            if (product.id !== productId) return product;

            console.log("modifiedProduct", { ...product, selectedSku: sku });
            return { ...product, selectedSku: sku };
        });
        dispatch(setProducts(modifiedProducts));
        dispatch(addSelectedSku(orders[0].id, productId, sku));
    };

    const showSkuModal = () => {
        presentSkuModal({
            title: "Sélection de la variation du produit",
            contentText: "Plusieurs choix s'offrent à vous. Sélectionnez une des options suivantes",
            preventClose: true,
        });
    };

    // clear promocode when exiting checkout
    useEffect(
        () => () => {
            dispatch(setPromocode(""));
            dispatch(setIsPromocodeValidated(false));
        },
        []
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [!loading]);

    //  This useEffect is used to determine if the user is about to leave the website.
    //  If they are leaving and have no completed their transaction, then add their cart
    //  to the adandonned cart collection in DB
    useEffect(() => {
        if ((user || checkoutDetails) && (products.length > 0 || subscriptions.length > 0)) {
            window.addEventListener("beforeunload", sendAbandonnedCart);
        }
        return () => window.removeEventListener("beforeunload", sendAbandonnedCart);
    }, [user, checkoutDetails, products]);

    useEffect(() => {
        // Is duo
        if (subscriptions.find(s => s.id === AKTIVATION_DUO_ID)) {
            setIsDuo(true);
            changeStepShow("duo", true);
        }

        const displayActiveAktPopup = async () => {
            if (user && user.subscriptions && user.subscriptions.length > 0) {
                const sub = user.subscriptions.find(s => settings.videoAccess.includes(s.id) && !s.expired);
                if (sub) {
                    const subscriptionPayload = await getRequest(`subscriptions/${sub.id}`);
                    pushNotification({
                        message: (
                            <div className="akt__sub__warning">
                                <p>Vous avez déjà un abonnement actif pour la plateforme Aktivation.</p>
                                <p>
                                    Si vous continuez votre achat, votre abonnement actuel sera annulé et remplacé par celui-ci.{" "}
                                    <u>Aucun remboursement sera accordé.</u>
                                </p>
                                <h3 className="akt__sub__warning__title">Votre forfait actuel:</h3>
                                <div className="akt__sub__warning__item">{subscriptionPayload.data.name}</div>
                            </div>
                        ),
                        options: {
                            key: generateKey(),
                            variant: "success",
                            popup: {
                                title: "Abonnement Aktivation détecté!",
                                showImage: true,
                                image: important,
                                buttonText: "Continuer l'achat",
                            },
                        },
                    });
                }
            }
        };

        // Active AKT sub message
        if (subscriptions.length > 0 && subscriptions.some(s => settings.videoAccess.includes(s.id))) {
            displayActiveAktPopup();
        }
    }, [subscriptions]);

    // this is used to watch for back navigation events.
    // when a user clicks the back button after an order has been submitted,
    // we must redirect them to '/'
    useEffect(() => {
        const pathname = window.location.pathname;
        if (pathname === "/checkout/confirmation") {
            window.addEventListener("popstate", e => hist.push("/"));
        }
    }, [window.location.pathname]);

    // display the sku modal for the lastest product
    useEffect(() => {
        if (!products.length) return;

        const lastAddedProduct = products[products.length - 1];
        if (lastAddedProduct.skus.length > 0 && !lastAddedProduct.selectedSku) {
            setItemToModify({ ...lastAddedProduct });
            showSkuModal();
        }
    }, [products]);

    // if the selected product is a kit and if one of the products contains a sku, fetch it
    useEffect(() => {
        if (!products.length) return;

        const lastAddedProduct = products[products.length - 1];

        if (lastAddedProduct.selectedSku || lastAddedProduct.kit?.length === 0) return;

        (async () => {
            const productsInKit = [];
            for (const { id } of lastAddedProduct.kit) {
                productsInKit.push((await getRequest(`products/${id}`)).data);
            }

            const productWithSku = productsInKit.find(p => p.skus.length > 0);

            if (!productWithSku) return;

            const itemInKit = lastAddedProduct.kit.find(item => item.id === productWithSku.id);

            if (itemInKit?.forcedSku) {
                changeProductSku(lastAddedProduct.id, itemInKit.forcedSku);
                return;
            }

            setItemToModify({ ...lastAddedProduct });
            setKitProductWithSku(productWithSku);
        })();
    }, [products]);

    useEffect(() => {
        if (kitProductWithSku) showSkuModal({ title: "Sélection de la variation du produit dans votre kit" });
    }, [kitProductWithSku]);

    if (!loading && !isTransactionCompleted && products.length === 0 && subscriptions.length === 0) return <Redirect to="/" />;

    return (
        <CheckoutContext.Provider
            value={{
                steps,
                activeStep,
                products,
                subscriptions,
                brand,
                brandAcronym,
                market,
                stripePaymentMethod,
                setStripePaymentMethod,
                stripeCustomerId,
                setStripeCustomerId,
                changeStepShow,
                getNextActiveStep,
                goToNextPage,
                gotToPreviousStep,
                setShowCart,
                isTransactionCompleted,
                setIsTransactionCompleted,
                isDuo,
                duoUser,
                setDuoUser,
                promoCodeValidated,
                setPromoCodeValidated,
                upsellAccepted,
                setUpsellAccepted,
                showSkuModal,
                setItemToModify,
            }}
        >
            <GetHelmet />
            {!hideNav && (
                <>
                    <GetNavbar />
                    <ContinueShopping brand={brand} />
                </>
            )}
            <Grid container className="checkout">
                <Grid item xs={12} lg={6}>
                    <main className={classes.layout}>
                        <Paper className={classes.paper}>
                            <Typography component="h1" variant="h4" align="center">
                                Commande
                            </Typography>
                            <Stepper activeStep={activeStep} className={classes.stepper}>
                                {Object.values(steps).map(
                                    ({ label, show }, index) =>
                                        show && (
                                            <Step key={index}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        )
                                )}
                            </Stepper>
                            <Router>
                                {Object.values(steps).map((s, index) => (
                                    <Route key={index} exact path={s.url} component={s.component} />
                                ))}
                            </Router>
                        </Paper>
                    </main>
                </Grid>
                <Grid item xs={12} lg={6}>
                    {showCart && <Cart />}
                    {!showCart && <OrderDetails />}
                </Grid>
            </Grid>
            {itemToModify?.skus.length && (
                <SkuModal
                    onContinue={() => {
                        changeProductSku(itemToModify.id, itemToModify.selectedSku);
                        dismissSkuModal();
                    }}
                    Modal={Modal}
                    product={itemToModify}
                    imgSrc={itemToModify.imageURLs[0]}
                    onSkuSelected={sku => {
                        console.log("sku", { ...itemToModify, selectedSku: sku ? sku.sku.value : null });
                        setItemToModify(i => ({ ...i, selectedSku: sku ? sku.sku.value : null }));
                    }}
                />
            )}
            {kitProductWithSku && (
                <SkuModal
                    onContinue={() => {
                        changeProductSku(itemToModify.id, itemToModify.selectedSku);
                        dismissSkuModal();
                    }}
                    Modal={Modal}
                    product={kitProductWithSku}
                    imgSrc={kitProductWithSku.imageURLs[0]}
                    onSkuSelected={sku => {
                        setItemToModify(i => ({ ...i, selectedSku: sku ? sku.sku.value : null }));
                        setKitProductWithSku(p => ({ ...p, selectedSku: sku ? sku.sku.value : null }));
                    }}
                />
            )}
        </CheckoutContext.Provider>
    );
};

export default Checkout;
