import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Assets
import Logo from "../../../assets/akt/logo-color.png";

import useFetch from "../../../hooks/useFetch";
import { getRequest } from "../../../actions/requests";

function Nav() {
    const [aktPresentation] = useFetch("Presentations/akt");
    const history = useHistory();

    const [isUnscrambled, setIsUnscrambled] = useState(false);

    const closeMenu = () => (document.getElementById("menu_toggler").checked = false);

    const checkUnscrambled = async () => {
        const payload = await getRequest("unscramblings/isUnscrambled");
        setIsUnscrambled(payload.data.isUnscrambled);
    };

    useEffect(() => {
        checkUnscrambled();
    }, []);

    return (
        <>
            <div className="nav">
                <div className="nav__main">
                    <a href="/" className="nav__logo clickable__tag">
                        <img src={Logo} alt="logo" />
                    </a>

                    <div className="nav__nav">
                        {/* <a className="clickable__tag" href="/#produits">
                            <span>Plans</span>
                        </a> */}

                        <a className="clickable__tag" href="/#programmes">
                            <span>Programmes</span>
                        </a>

                        <a className="clickable__tag" href="https://www.jimmysevigny.com/boutique/aktivation/" target="_blank">
                            <span>Produits</span>
                        </a>

                        {/* <span className="clickable__tag" onClick={() => history.push("/corporatif")}>
                            Corporatif
                        </span> */}

                        <span className="clickable__tag" onClick={() => history.push("/ambassadeurs")}>
                            Ambassadeurs
                        </span>

                        <span className="clickable__tag" onClick={() => history.push("/portail")}>
                            Connexion
                        </span>

                        <div className="nav__cta">
                            <a href="#produits">
                                <button className="nav__cta1 clickable__tag">JE M'INSCRIS</button>
                            </a>

                            {!isUnscrambled ? (
                                <a href="./#produits">
                                    <button className="nav__cta2">14 JOURS GRATUITS!</button>
                                </a>
                            ) : (
                                <a href="/portail/login">
                                    <button className="nav__cta2" style={{ fontSize: 15 }}>
                                        GRAND DÉBROUILLAGE
                                    </button>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="nav__mobile">
                <div className="nav__mobile__container">
                    <a href="/">
                        <img src={Logo} alt="logo" />
                    </a>

                    <div className="menu-wrap">
                        <input id="menu_toggler" type="checkbox" className="toggler" />
                        <div className="hamburger">
                            <div></div>
                        </div>
                        <div className="menu">
                            <div>
                                <div>
                                    <ul>
                                        {/* <li>
                                            <a href="/#produits" onClick={() => closeMenu()}>
                                                <span className="clickable__tag">Plans</span>
                                            </a>
                                        </li> */}
                                        <li>
                                            <a href="/#programmes" onClick={() => closeMenu()}>
                                                <span className="clickable__tag">Programmes</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.jimmysevigny.com/boutique/aktivation/" target="_blank">
                                                <span className="clickable__tag">Produits</span>
                                            </a>
                                        </li>
                                        <li>
                                            <span className="clickable__tag" onClick={() => history.push("/corporatif")}>
                                                Corporatif
                                            </span>
                                        </li>
                                        <li>
                                            <span className="clickable__tag" onClick={() => history.push("/ambassadeurs")}>
                                                Ambassadeurs
                                            </span>
                                        </li>
                                        <li>
                                            <span className="clickable__tag" onClick={() => history.push("/portail")}>
                                                Connexion
                                            </span>
                                        </li>
                                        <div className="nav__cta">
                                            <a href="#produits">
                                                <button className="nav__cta1 clickable__tag" onClick={() => closeMenu()}>
                                                    JE M'INSCRIS
                                                </button>
                                            </a>

                                            {!isUnscrambled ? (
                                                <a href="./#produits">
                                                    <button className="nav__cta2" onClick={() => closeMenu()}>
                                                        14 JOURS GRATUITS!
                                                    </button>
                                                </a>
                                            ) : (
                                                <a href="/portail/login">
                                                    <button className="nav__cta2" onClick={() => closeMenu()}>
                                                        GRAND DÉBROUILLAGE
                                                    </button>
                                                </a>
                                            )}
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Corpo open={openDialog} onClose={handleCloseDialog} /> */}
        </>
    );
}

export default Nav;
