import React from "react";

import { useHistory } from "react-router-dom";

function Lh() {
    const hist = useHistory();
    return (
        <div className="lh">
            <div className="lh__container">
                <div className="propulsed">
                    <span>
                        Propulsé par{" "}
                        <a href="https://leadhouse.ca" target="_blank">
                            LEADHOUSE
                        </a>
                    </span>
                </div>
                <div className="politic">
                    <span onClick={() => hist.push("/confidentialite")}>Politique de confidentialité</span>
                </div>
            </div>
        </div>
    );
}

export default Lh;
