import React, { useState, useContext, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";

// Material UI Core
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Custom Components
import LogoAKT from "../../../assets/portal/logo-aktivation.png";
import LogoGM from "../../../assets/gm/gm-logo-color.png";
import LogoGC from "../../../assets/gc/gc-login.png";
import LogoMCF from "../../../assets/mcf/js-signature-blue.png";
import LogoGK from "../../../assets/gk/logo-gokrisp.png";
import LogoGG from "../../../assets/gg/logo.png";
import { login } from "../../../utils/auth";

// Custom Components
import { getLandingPageInfo } from "../../../utils/config";
import { GetHelmet } from "../../../utils/config";

// Notifications from redux
import { snackbarEnqueuedAction, generateKey } from "../../../store/reducers/snackbars";
import { useDispatch } from "react-redux";

// api requests
import { getRequest, postRequest } from "../../../actions/requests";
import { settings } from "../../../settings";
import { emailRegex } from "../../../utils/regex";
import Analytics from "../../../services/analytics";
import useGetUser from "../../../general/useGetUser";
import { UserContext } from "../../../reducer/userContext";

const Login = () => {
    const { brand, brandAcronym } = getLandingPageInfo();
    const { setUserEnteredUnscramblingEmail, isUnscrambled } = useContext(UserContext);
    const { user } = useGetUser();
    const dispatch = useDispatch();
    const hist = useHistory();

    // States
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showOldSubMessage, setOldSubMessage] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [dialogEmail, setDialogEmail] = useState("");

    // notifications functions
    const pushNotification = (...args) => dispatch(snackbarEnqueuedAction(...args));

    const handleLoginSuccess = payload => {
        if (payload.data.hasVideoAccess) {
            hist.push("/portail/videos");
        }
    };

    const handleLoginError = async err => {
        // user not found in firebase authentication. Look for user in "AktSubscribedUsers" collection.
        if (err.code === "auth/user-not-found" && brand !== "Aktivation") {
            pushNotification({
                message: "Aucun compte associé avec ce courriel",
                options: {
                    key: generateKey(),
                    variant: "error",
                },
            });
        } else if (err.code === "auth/user-not-found" && brand === "Aktivation") {
            try {
                await getRequest(`${settings.urls.aktSubscribedUsers}/${email.toLowerCase()}`);
                setOldSubMessage(true);
            } catch (e) {
                pushNotification({
                    message: "Aucun compte associé avec ce courriel",
                    options: {
                        key: generateKey(),
                        variant: "error",
                    },
                });
            }
        } else if (err.code === "auth/wrong-password")
            pushNotification({
                message: "Combinaison de courriel et mot de passe incorrecte",
                options: {
                    variant: "error",
                },
            });
        else if (err.code === "auth/invalid-email")
            pushNotification({
                message: "Courriel invalide",
                options: {
                    variant: "error",
                },
            });
    };

    const properLogo = () => {
        switch (brandAcronym) {
            case "MCF":
                return LogoMCF;
            case "GM":
                return LogoGM;
            case "AKT":
                return LogoAKT;
            case "GC":
                return LogoGC;
            case "GK":
                return LogoGK;
            case "GG":
                return LogoGG;
            default:
                return LogoGC;
        }
    };

    const displayOldSubMessage = () => {
        if (brand !== "Aktivation") return null;
        if (showOldSubMessage)
            return (
                <div style={{ textAlign: "center" }}>
                    {settings.aktExistingSub}
                    <Button className={`login__cta ${brand}`} variant="outlined" onClick={() => hist.push("/#produits")}>
                        Activer un forfait
                    </Button>
                </div>
            );
        else return null;
    };

    const accessUnscrambledVideos = async () => {
        if (dialogEmail && emailRegex.test(dialogEmail)) {
            // add email to the list
            const payload = await postRequest("unscramblings/addEntry", {
                email: dialogEmail,
            });

            if (payload.status === 200) {
                Analytics.newLead(dialogEmail);
                setUserEnteredUnscramblingEmail(true);
                hist.push("/portail/debrouillage");
            }
        } else {
            pushNotification({
                message: "Courriel invalide",
                options: {
                    key: generateKey(),
                    variant: "error",
                },
            });
        }
    };

    if (user?.hasVideoAccess) return <Redirect to="/portail/videos" />;
    if (user) return <Redirect to="/portail/profil" />;

    return brand === "Aktivation" ? (
        <div className={`login__page akt`}>
            <GetHelmet />
            {/* <Nav /> */}
            <Card className="login__container akt">
                <CardContent>
                    <img onClick={() => hist.push("/")} src={properLogo()} alt="Leadhouse Logo" className="login__logo" />
                    {displayOldSubMessage()}
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            login(email, password, handleLoginSuccess, handleLoginError);
                        }}
                    >
                        <div className="login__actions">
                            <TextField id="email" label="Courriel" type="text" onChange={e => setEmail(e.currentTarget.value)} required fullWidth />
                        </div>
                        <div className="login__actions">
                            <TextField
                                id="password"
                                label="Mot de passe"
                                type="password"
                                onChange={e => setPassword(e.currentTarget.value)}
                                required
                                fullWidth
                            />
                        </div>
                        <div className="login__actions">
                            <Button className={`login__cta ${brand}`} type="submit" variant="contained">
                                C'est parti!
                            </Button>
                        </div>
                    </form>
                    <div className="login__actions">
                        <Button onClick={() => hist.push("/motDePasseOublie")}>Oublié mot de passe?</Button>
                    </div>
                    {isUnscrambled && (
                        <div className="login__actions">
                            <Button className={`login__cta ${brand}`} variant="contained" onClick={() => setIsOpen(true)}>
                                Accéder au débrouillage
                            </Button>
                        </div>
                    )}
                </CardContent>
            </Card>

            <Dialog open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Grand Débrouillage</DialogTitle>
                <DialogContent>
                    <DialogContentText>Veuiller entrer votre courriel pour participer au Grand Débrouillage.</DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        label="Courriel"
                        type="email"
                        value={dialogEmail}
                        onChange={e => setDialogEmail(e.target.value)}
                        fullWidth
                        focused
                        onKeyDown={e => {
                            if (e.key === "Enter") accessUnscrambledVideos();
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsOpen(false)} color="primary">
                        Retour
                    </Button>
                    <Button onClick={accessUnscrambledVideos} color="primary">
                        Accéder
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    ) : (
        <div className={`login__page ${brand}`}>
            <GetHelmet />
            <Card className="login__container">
                <CardContent>
                    <img onClick={() => hist.push("/")} src={properLogo()} alt="Leadhouse Logo" className="login__logo" />
                    {displayOldSubMessage()}
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            login(email, password, handleLoginSuccess, handleLoginError);
                        }}
                    >
                        <div className="login__actions">
                            <TextField id="email" label="Courriel" type="text" onChange={e => setEmail(e.currentTarget.value)} required fullWidth />
                        </div>
                        <div className="login__actions">
                            <TextField
                                id="password"
                                label="Mot de passe"
                                type="password"
                                onChange={e => setPassword(e.currentTarget.value)}
                                required
                                fullWidth
                            />
                        </div>
                        <div className="login__actions">
                            <Button className={`login__cta ${brand}`} type="submit" variant="contained">
                                Connexion
                            </Button>
                        </div>
                    </form>
                    <div className="login__actions">
                        <Button onClick={() => hist.push("/motDePasseOublie")}>Oublié mot de passe?</Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default Login;
