import ReactFP from "react-facebook-pixel";
// import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module';

const sendEvent = (eventName, event) => {
    try {
        // ReactGA.event(eventName, event)
        window.dataLayer.push({ event: eventName, ecommerce: event });
        ReactFP.trackCustom(eventName, event);
    } catch (e) {
        console.error(e);
    }
};

const tagManagerArgs = {
  gtmId: 'GTM-TCHHF3T'
}

export default {
    init: () => {
        ReactFP.init("1422379857910508");
        // ReactGA.initialize([
        //     {
        //       trackingId: "G-X2PFVPSR8D",
        //     },
        // ]);
        TagManager.initialize(tagManagerArgs)
    },

    // when user item to cart (email step)
    add_to_cart: items => {
        let total = items.reduce((previousValue, currentValue) => {
            if ("price" in currentValue) {
                // product
                previousValue += currentValue.price.amount;
            } else if ("rate" in currentValue) {
                // subscription
                previousValue += currentValue.rate.amount;
            }
            return previousValue;
        }, 0);

        const event = {
            currency: "CAD",
            value: total / 100,
            items: items.map(item => ({ item_id: item.id, item_name: item.name, price: item.price / 100, quantity: 1 })),
        };
        sendEvent("add_to_cart", event);
    },

    // when user reaches step "facturation"
    begin_checkout: items => {
        let total = items.reduce((previousValue, currentValue) => {
            if ("price" in currentValue) {
                // product
                previousValue += currentValue.price.amount;
            } else if ("rate" in currentValue) {
                // subscription
                previousValue += currentValue.rate.amount;
            }
            return previousValue;
        }, 0);

        const event = {
            currency: "CAD",
            value: total / 100,
            items: items.map(item => ({ item_id: item.id, item_name: item.name, price: item.price / 100, quantity: 1 })),
        };
        sendEvent("begin_checkout", event);
    },

    // when user reaches step "paiement"
    add_payment_info: (order, items) => {
        const event = {
            currency: "CAD",
            value: order.totalGrand / 100,
            items: items.map(item => ({ item_id: item.id, item_name: item.name, price: item.price / 100, quantity: 1 })),
            coupon: order.promoCode,
            discount: order.amountSaved / 100,
        };
        sendEvent("add_payment_info", event);
    },

    // when user reaches completes checkout for FREE TRIAL
    free_trial: (order, items) => {
        const event = {
            currency: "CAD",
            value: order.totalGrand / 100,
            items: items.map(item => ({ item_id: item.id, item_name: item.name, coupon: order.promoCode, discount: order.amountSaved / 100 })),
        };
        sendEvent("free_trial", event);
    },

    // when a real payment is processed (purchases, renewals)
    purchase: (order, items, transactionId, purchaseType) => {
        const event = {
            transaction_id: transactionId,
            value: order.totalGrand / 100,
            tax: order.totalTaxes / 100,
            currency: "CAD",
            coupon: order.promoCode,
            items: items.map(item => ({
                item_id: item.id,
                item_name: item.name,
                coupon: order.promoCode,
                discount: order.amountSaved / 100,
                item_category5: purchaseType,
                price: item.price / 100,
                quantity: item.quantity,
            })),
        };
        sendEvent("purchase", event);
    },
    newLead: email => {
        const eventName = "lead";
        const event = { email };

        sendEvent(eventName, event);
    },
};
